
import {defineComponent} from "vue";
import Button from "@/components/UI/Button.vue";
import {auth0} from "@/main";

export default defineComponent({
  components: {Button},
  data() {
    return {
      event: '',
      isLoading: false
    };
  },
  methods: {
    signIn() {
      this.isLoading = true
      // const toPath = this.$route.query.toPath ? (this.$route.query.toPath as string) : '/'
      auth0.loginWithRedirect();
    }
  }
})
